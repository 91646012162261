export interface Pagination {
  take: number;
  skip: number;
}

export interface FilterOption {
  key: string;
  name: string;
  icon: string;
}

export interface Filter {
  key: string;
  name: string;
  options: FilterOption[];
}

export const graphRunFilters: Filter[] = [
  {
    key: 'type',
    name: 'Type',
    options: [
      {
        key: 'manual',
        name: 'Manual Run',
        icon: 'i-ph-play-circle',
      },
      {
        key: 'trigger',
        name: 'Triggered Run',
        icon: 'i-ph-lightning',
      },
      // TODO: Add bulk run, will require RunGroup UNION
      // {
      //   key: 'bulk',
      //   name: 'Bulk Run',
      //   icon: 'i-ph-list-checks',
      // },
      {
        key: 'scheduled',
        name: 'Scheduled Run',
        icon: 'i-ph-clock',
      },
    ],
  },
  {
    key: 'state',
    name: 'Status',
    options: [
      {
        key: 'pending',
        name: 'Pending',
        icon: 'i-ph-hourglass',
      },
      {
        key: 'skipped',
        name: 'Skipped',
        icon: 'i-ph-skip-forward-circle',
      },
      {
        key: 'inProgress',
        name: 'In Progress',
        icon: 'i-ph-play-circle',
      },
      {
        key: 'canceled',
        name: 'Canceled',
        icon: 'i-ph-x-circle',
      },
      {
        key: 'error',
        name: 'Error',
        icon: 'i-ph-warning',
      },
      {
        key: 'paused',
        name: 'Paused',
        icon: 'i-ph-pause-circle',
      },
      {
        key: 'success',
        name: 'Success',
        icon: 'i-ph-check-circle',
      },
    ],
  },
  // TODO: Implement Integration search
  // {
  //   key: 'integration',
  //   name: 'Integration',
  //   options: [
  //     {
  //       key: 'notion',
  //       name: 'Notion',
  //       icon: 'i-logos-notion',
  //     },
  //     {
  //       key: 'google',
  //       name: 'Google',
  //       icon: 'i-logos-google-icon',
  //     },
  //     {
  //       key: 'linear',
  //       name: 'Linear',
  //       icon: 'i-logos-linear-icon',
  //     },
  //     {
  //       key: 'hubspot',
  //       name: 'Hubspot',
  //       icon: 'i-fa6-brands-hubspot',
  //     },
  //   ],
  // },
];
